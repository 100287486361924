<script>
export default {
  name: 'RootApp',
  methods: {
    logout() {
      this.$store.dispatch('auth/logout', () => this.$router.push('/'));
    },
  },
  created() {
    this.$store.dispatch('auth/ready');
  },
};
</script>

<template>
  <div id="app">
    <b-loading v-if="!$store.state.auth.ready" active />
    <template v-else>
      <header class="has-background-primary has-text-white py-3">
        <nav class="level container">
          <div class="level-left">
            <router-link class="color-inherit" to="/">
              <strong>Coupi DailyOrder</strong>
            </router-link>
          </div>
          <div v-if="$store.getters['auth/isLoggedIn']" class="level-right">
            <b-button type="is-white" tag="router-link" :to="{name: 'admin_dates'}">
              Jours
            </b-button>
            <b-button class="ml-2" type="is-white" tag="router-link" :to="{name: 'admin_products'}">
              Produits
            </b-button>
            <b-button class="ml-2" type="is-white" tag="router-link" :to="{name: 'admin_zones'}">
              Zones
            </b-button>
            <b-button class="ml-2" type="is-white" @click="logout">
              Déconnexion
            </b-button>
          </div>
          <div v-else class="level-right">
            <b-button type="is-white" tag="router-link" :to="{name: 'login'}" outlined>
              Connexion
            </b-button>
            <b-button class="ml-2" type="is-white" tag="router-link" :to="{name: 'register'}">
              Inscription
            </b-button>
          </div>
        </nav>
      </header>
      <div class="container">
        <router-view/>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
</style>
