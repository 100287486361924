import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyD-f8fA3rtwtu44lDaaUx15ni1u8F7LTdQ',
  authDomain: 'coupi-bar.firebaseapp.com',
  projectId: 'coupi-bar',
  storageBucket: 'coupi-bar.appspot.com',
  messagingSenderId: '783524043562',
  appId: '1:783524043562:web:e6540cdeec9eeb441a9c53',
  measurementId: 'G-9G0192ZK0S',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const $firebase = {
  auth: firebase.auth(),
  firestore: firebase.firestore(),
};

Vue.prototype.$firebase = $firebase;

$firebase.auth.languageCode = 'fr';
$firebase.firestore.languageCode = 'fr';

export default firebase;
export const { auth } = $firebase;
export const { firestore } = $firebase;
