import { firestore, auth } from '@/config/firebase';
import errorHandlers from '@/config/errors';

export default {
  logout() {
    return auth.signOut();
  },
  login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
      .then((res) => this.find(res.user.uid))
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
  register(data) {
    return auth.createUserWithEmailAndPassword(data.email, data.password)
      .then((res) => {
        const user = {
          uid: res.user.uid,
          role: 'customer',
          ...data,
        };

        firestore.collection('users')
          .doc(res.user.uid)
          .set(user);

        return user;
      })
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
  find(uid) {
    return firestore.collection('users')
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }

        auth.signOut();

        throw new Error('Cette donnée n\'existe pas');
      })
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
};
