import { firestore } from '@/config/firebase';
import errorHandlers from '@/config/errors';
import Generic, { collectionToData } from '@/services/API/_Generic';

export default {
  ...Generic('dates'),

  getWhere(where = []) {
    let query = firestore.collection('dates');

    where.forEach((v) => {
      query = query.where(v[0], v[1], v[2]);
    });

    return query.get()
      .then(collectionToData)
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
};
