<script>
import APIDate from '@/services/API/Date';

export default {
  name: 'PageHome',
  data() {
    return {
      dates: [],
      currentDate: null,
      today: this.$moment().format('YYMMDD'),
      todayDash: this.$moment().startOf('day').format('YYYY-MM-DD'),
      isThisWeek: false,
      chosenDate: null,
    };
  },
  created() {
    const startOfWeek = this.$moment().startOf('isoWeek');
    const endDate = this.$moment().startOf('isoWeek').add(4, 'days').format('YYMMDD');
    const isThisWeek = this.today <= endDate;
    const begin = isThisWeek ? startOfWeek : startOfWeek.add(7, 'days');

    this.isThisWeek = isThisWeek;

    this.dates.push({
      full: begin.format('YYMMDD'),
      date: begin.format('DD'),
      dash: begin.startOf('day').format('YYYY-MM-DD'),
    });
    this.dates.push({
      full: begin.add(1, 'days').format('YYMMDD'),
      date: begin.format('DD'),
      dash: begin.startOf('day').format('YYYY-MM-DD'),
    });
    this.dates.push({
      full: begin.add(1, 'days').format('YYMMDD'),
      date: begin.format('DD'),
      dash: begin.startOf('day').format('YYYY-MM-DD'),
    });
    this.dates.push({
      full: begin.add(1, 'days').format('YYMMDD'),
      date: begin.format('DD'),
      dash: begin.startOf('day').format('YYYY-MM-DD'),
    });
    this.dates.push({
      full: begin.add(1, 'days').format('YYMMDD'),
      date: begin.format('DD'),
      dash: begin.startOf('day').format('YYYY-MM-DD'),
    });

    this.chosenDate = this.isThisWeek ? this.todayDash : this.dates[0].dash;
    APIDate.getWhere([['datetime', '==', this.chosenDate]])
      .then(([date]) => (this.currentDate = date));
  },
  methods: {
    chooseDate(date) {
      this.chosenDate = date.dash;
      APIDate.getWhere([['datetime', '==', this.chosenDate]])
        .then(([date]) => (this.currentDate = date));
    },
  },
};
</script>

<template>
  <section class="section">
    <div class="tabs is-centered is-large">
      <ul>
        <li :class="{'is-active': chosenDate === dates[0].dash}">
          <a @click.prevent="chooseDate(dates[0])">Lundi ({{ dates[0].date }})</a>
        </li>
        <li :class="{'is-active': chosenDate === dates[1].dash}">
          <a @click.prevent="chooseDate(dates[1])">Mardi ({{ dates[1].date }})</a>
        </li>
        <li :class="{'is-active': chosenDate === dates[2].dash}">
          <a @click.prevent="chooseDate(dates[2])">Mercredi ({{ dates[2].date }})</a>
        </li>
        <li :class="{'is-active': chosenDate === dates[3].dash}">
          <a @click.prevent="chooseDate(dates[3])">Jeudi ({{ dates[3].date }})</a>
        </li>
        <li :class="{'is-active': chosenDate === dates[4].dash}">
          <a @click.prevent="chooseDate(dates[4])">Vendredi ({{ dates[4].date }})</a>
        </li>
      </ul>
    </div>
    <template v-if="currentDate">
      <div v-for="product in currentDate.products" :key="product.id" class="box">
        {{ product.name }}
      </div>
    </template>
  </section>
</template>
