import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from '@/config/store/auth';

Vue.use(Vuex);

// eslint-disable-next-line
[auth].forEach((v) => (v.namespaced = true));

export default new Vuex.Store({
  modules: {
    auth,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
});
