import { auth } from '@/config/firebase';
import APIUser from '@/services/API/User';

export const mutations = {
  user(state, user) {
    state.user = user;
  },
};

export const actions = {
  ready({ state, getters, commit }) {
    return new Promise((resolve) => {
      console.log('start ready', state.ready);
      if (state.ready) {
        resolve(getters.isLoggedIn);
        return;
      }

      const observer = auth.onAuthStateChanged((user) => {
        console.log('auth changed', user);
        observer();
        if (!user) {
          state.ready = true;
          commit('user', user);
          resolve(getters.isLoggedIn);
          return;
        }

        APIUser.find(user.uid)
          .then((user) => {
            state.ready = true;
            commit('user', user);
            resolve(getters.isLoggedIn);
          });
      });
    });
  },
  register({ commit }, data) {
    return APIUser.register(data)
      .then((user) => commit('user', user));
  },
  login({ commit }, data) {
    return APIUser.login(data.email, data.password)
      .then((user) => commit('user', user));
  },
  logout({ commit }, redirect) {
    return APIUser.logout()
      .then(() => redirect()
        .then(() => commit('user', null))
        .catch(() => {}));
  },
};

export const getters = {
  isLoggedIn(state) {
    return !!state.user;
  },
  user(state) {
    return state.user;
  },
  role(state) {
    return state.user && state.user.role;
  },
};

export const state = {
  ready: false,
  user: null,
};
