import './assets/styles/index.scss';

import Vue from 'vue';
import App from './App.vue';
import './config/firebase';
import './config/moment';
import './config/buefy';
import router from './config/router';
import store from './config/store';
import './config/errors';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
