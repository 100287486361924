import { firestore } from '@/config/firebase';
import errorHandlers from '@/config/errors';

export const collectionToData = (snap) => {
  const data = [];
  snap.forEach((v) => data.push({ id: v.id, ...v.data() }));
  return data;
};

export default (key) => ({
  get() {
    return firestore.collection(key)
      .get()
      .then(collectionToData)
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
  find(id) {
    return firestore.collection(key)
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }

        throw new Error('Cette donnée n\'existe pas');
      })
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
  add(data) {
    return firestore.collection(key)
      .add(data)
      .then((doc) => ({ id: doc.id, ...data }))
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
  remove(id) {
    return firestore.collection(key)
      .doc(id)
      .delete()
      .catch((error) => {
        errorHandlers.global(error);
        throw error;
      });
  },
});
