import Vue from 'vue';
import { DialogProgrammatic as Dialog } from 'buefy';

const errorHandlers = {
  global(error) {
    Dialog.alert({
      type: 'is-danger',
      message: error.message,
    });
  },
};

Vue.prototype.$errorHandlers = errorHandlers;

export default errorHandlers;
