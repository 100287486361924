import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/config/store';
import Home from '@/pages/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/connexion',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/inscription',
    name: 'register',
    component: () => import('@/pages/Register.vue'),
  },
  {
    path: '/lesduv',
    meta: {
      auth: ['admin'],
    },
    component: () => import('@/pages/Admin.vue'),
    children: [
      {
        path: '',
        name: 'admin_index',
      },
      {
        path: 'produits',
        name: 'admin_products',
        component: () => import('@/pages/admin/Products.vue'),
      },
      {
        path: 'zones',
        name: 'admin_zones',
        component: () => import('@/pages/admin/Zones.vue'),
      },
      {
        path: 'jours',
        name: 'admin_dates',
        component: () => import('@/pages/admin/Dates.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const authMiddleware = (to, from, next) => {
  let auth;

  to.matched.forEach((v) => {
    if ('auth' in v.meta) {
      auth = v.meta.auth;
    }
  });

  console.log('auth', auth);
  store.dispatch('auth/ready')
    .then((isLoggedIn) => {
      console.log('ready in router');
      const user = store.getters['auth/user'];
      if (auth === true && !isLoggedIn) {
        next({ name: 'login' });
      } else if (auth === false && isLoggedIn) {
        next('/');
      } else if (typeof auth === 'object' && (!isLoggedIn || !auth.includes(user.role))) {
        next('/');
      } else {
        next();
      }
    });
};

router.beforeEach(authMiddleware);

export default router;
